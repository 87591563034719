body {

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container1 {
  background-color: #ffffff;
}

.navbar {
  border-bottom: solid 1px #EBEBEB;
  background-color: rgb(255, 255, 255);
}

.bottom-tab-nav {
  border-top: solid 1px #EBEBEB;
}

.nav-link,
.bottom-nav-link {
  color: #ff5a78;
  color: linear-gradient(0deg, rgba(255, 24, 67, 1) 0%, rgba(255, 126, 149, 1) 100%);
}

.bottom-nav-link.active {
  color: #FF1843;
}

.bottom-tab-label {
  font-size: 12px;
}

.gr {
  /* background-color: #ffffff;*/
  /* background-image: linear-gradient(270deg, rgb(255, 89, 120) 0%, rgba(255, 126, 149, 1) 100%); */
  /* background-image: linear-gradient(270deg, rgb(30, 200, 72) 0%, rgba(16, 235, 137, 1) 100%); */

  /* background: linear-gradient(90deg, rgba(224,109,109,1) 0%, rgba(193,19,19,1) 100%); */
  /* background: linear-gradient(90deg, rgba(240,154,74,1) 0%, rgba(223,119,21,1) 100%); */
  background: linear-gradient(90deg, #FCCF31 0%, #F55555 100%);
  /* background: #0fb1ff; */
  /* background: linear-gradient(54deg, rgb(47,133,254) 0%, rgba(47,133,254,1) 100%); */

}

.gr-blue {
  background: linear-gradient(90deg, rgba(80, 107, 168, 1) 0%, rgba(25, 42, 81, 1) 100%);
}

.gr-orange {
  /* background: rgb(24, 255, 82); */
  background: linear-gradient(90deg, #FCCF31 0%, rgba(222, 143, 39, 1) 100%);
}

.gr-green {
  background: rgb(24, 255, 82);
  background: linear-gradient(90deg, rgba(255, 198, 24, 1) 0%, rgba(222, 143, 39, 1) 100%);
}

.border-radius-25 {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

.border-radius-15 {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.deal-box {
  margin: 5px;
  /*min-height: 200px;*/
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  height:auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-box {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.title {
  margin: 0px;
  display: flex;
  align-items: left;
  justify-content: left;
  color: #3F494E;
}

.block {
  margin: 0px;
  display: flex;
  align-items: left;
  justify-content: left;
  color: #3F494E;
}

.block p {
  color: #9c9c9c;
}

.buy-button {
  color: rgb(255, 255, 255);
  padding: 5px;
  width: 100%;
  /* background-color:#FFB800;   */
  background-color: #007bff;
  border: none;
  font-size: 20px;
}

.logout-button {
  color: #FFF;
  padding: 5px;
  width: 100%;
  background-color: #007bff;
  border: none;
  font-size: 20px;
}

.swal2-popup {
  font-size: 16px !important;
  font-family: Georgia, serif;
}



.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.custom-navbar {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(253, 253, 253, 0));
  ;
  /* Set the background to transparent */
}


@keyframes typing {
  from {
    width: 0;
    caret-color: transparent;
    /* Hide the cursor during animation */
  }

  to {
    width: 70%;
    caret-color: initial;
    /* Revert to the default cursor color */
  }
}

.typing-animation {
  display: inline-block;
  animation: typing 2s steps(40, end) both, fadeIn 2s ease-in-out 2s;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.scroll-content {
  white-space: nowrap;
  animation: scroll 5s linear;
  /* Adjust animation duration as needed and set it to infinite for continuous scrolling */
  display: flex;
}

.scroll-content::-webkit-scrollbar {
  width: 0.01px; /* Set an extremely small width to hide the scrollbar in WebKit browsers */
}


@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-50%);
    /* Scroll halfway to the left */
  }

  100% {
    transform: translateX(0);
    /* Return to the original position */
  }
}

.button-style1 {
  background: rgba(80, 107, 168, 1) 0%;
  padding: 5px 0px;
  border-radius: 5px;
  text-align: center;
  vertical-align: center;
  color: white;
  font-size: 15px;
  cursor: pointer;
  transition: background 0.3s;
}

.button-style2 {
  background: rgba(255, 198, 24, 1) 0%;
  padding: 5px 0px;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  vertical-align: center;
  color: white;
  font-size: 15px;
  cursor: pointer;
  transition: background 0.3s;
}

.button-style3 {
  background: rgba(255, 198, 24, 1) 0%;
  padding: 5px 0px;
  border-radius: 5px;
  text-align: center;
  vertical-align: center;
  color: white;
  font-size: 15px;
  cursor: pointer;
  transition: background 0.3s;
}

.button-style4 {
  background: rgba(255, 198, 24, 1) 0%;
  padding: 5px 0px;
  border-radius: 5px;
  text-align: center;
  vertical-align: center;
  color: white;
  font-size: 15px;
  cursor: pointer;
  transition: background 0.3s;
}

.mydeal-box {
  margin: 5px;
  display: flex;
  align-items: left;
  margin-bottom: "25px";
}

.imagecard1 {
  border-radius: 5px;
  text-align: left;
  vertical-align: center;
  color: rgb(0, 0, 0);
  font-size: 15px;
  cursor: pointer;
  transition: background 0.3s;
}

.imagecard2 {
  border-radius: 5px;
  text-align: left;
  vertical-align: center;
  color: rgb(0, 0, 0);
  cursor: pointer;
  transition: background 0.3s;
}

.imagecard3 {
  border-radius: 5px;
  text-align: left;
  vertical-align: center;
  color: rgb(0, 0, 0);
  font-size: 15px;
  cursor: pointer;
  transition: background 0.3s;
}

.imagecard4 {
  border-radius: 5px;
  text-align: left;
  vertical-align: center;
  color: rgb(0, 0, 0);
  font-size: 15px;
  cursor: pointer;
  transition: background 0.3s;
}


/* custom-carousel.css */
.carousel .control-arrow.control-prev,
.carousel .control-arrow.control-next,
.carousel .carousel-status,
.carousel .thumbs-wrapper,
.carousel .control-dots {
  display: none;
  /* Hide the control arrows */
}

/* Popular */


.card-container {
  padding: 5px;
  height: 250px;
  /* Add 4px of padding around the entire card */
}

.my-card {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 100%;
  height: auto;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}


.custom-card-body {
  min-height: 50px;
  /* Set your desired minimum height here */
  padding: 10px;
}

.card-title {
  color: rgba(25, 42, 81, 1);
  font-weight: bolder;
  font-size: 18px;
}

.card-text {
  font-size: 15px;
  color: royalblue;
}

.cd-my-card {
  overflow: hidden;
  position: relative;
}

.overlay-arrow {
  position: absolute;
  top: 5%;
  left: 5%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 0px;
  color: white;
}

.cd-image-overlay {
  position: absolute;
  top: 49%;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  z-index: 1;
  pointer-events: none;
  transform: translateY(-50%);
}

.overlay-text {
  padding: 10px;
}

.pc-card-title {
  color: rgba(25, 42, 81, 1);
  font-weight: bolder;
  font-size: 18px;
  margin-bottom: 0px;
}

.pc-card-text {
  font-size: 18px;
  color: #9c9c9c;
}

.heading {
  color: #3F494E;
  margin-bottom: 0px;
}

.card-style {
  width: 200px;
  height: 30px;
  background-color:    #ffffff;
  border-radius: 50px;
  padding: 5px;
  position: absolute;
  top: 75%;
  left: 3%;
  align-items: center;
  display: flex;
  z-index: 2;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}



/* CSS for the arrow and accordion */
.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 1.5px solid #e7e7e7;
  transition: border 0.3s ease;
}

.arrow-icon {
  color: #9c9c9c;
  transition: transform 0.3s ease, color 0.3s ease;
}


.accordion-header.open {
  border-bottom: none;
}

.arrow-icon.open {
  transform: rotate(180deg);
  color: #9c9c9c;
}

.accordian-title {
  color: #858585;
}

.accordian-description {
  color: #858585;
  font-size: 18px;
}

.filter-container {
  display: flex;
  background-color: #FFF;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  height: auto;
  padding: 0px;
  scrollbar-width: none;
  /* Hide the scrollbar */
}

.filter-container::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar in WebKit browsers */
}

.filter-button,
.filter-badge-button {
  border: 1px solid #ccc;
  background-color: #FFF;
  padding: 7px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 20px;
  margin: 0 5px;
}

/* Companies Page - Restaurants */

.restaurant-spacing{

padding: 10px;

}

.check-deals{
  padding: 3px 5px;
  border-radius: 15px;
}